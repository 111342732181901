import { ValidationField } from '@temperworks/types'

export default function getValidationPresets(t: Function) {
  const presets: Record<string, ValidationField|((arg:any)=>ValidationField)> = {
    firstName: {
      name: t('signUpPage.firstName'),
      value: null,
      rules: ['required', 'min:2', 'max:20']
    },
    lastName: {
      name: t('signUpPage.lastName'),
      value: null,
      rules: ['required', 'min:2', 'max:20']
    },
    companyName: ({ value, country }) => ({
      name: t(`signUpPage.companyName.${country}`),
      value,
      rules: ['required']
    }),
    password: ({ value, isValid }) => ({
      name: t('signUpPage.password'),
      value,
      rules: [
        'required',
        `isTrue:${isValid}`
      ]
    }),
    iban: {
      name: t('completeAccountWizard.wizardSteps.invoiceDetails.form.iban.title'),
      value: null,
      rules: ['required']
    },
    vat: {
      name: t('completeAccountWizard.wizardSteps.invoiceDetails.form.vat.title'),
      value: null,
      rules: ['required']
    }
  }
  return presets
}
