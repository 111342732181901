<script setup lang="ts">
import {
  search,
  person,
  calendar,
  clock,
  glasses,
  glassesCrossed,
  timer,
  shoppingBag,
  alertOff,
  alertOn,
  document,
  documentAccepted,
  documentDeclined,
  telephone,
  email,
  profile,
  profileSolid,
  folder,
  camera,
  heart,
  image,
  imageEmpty,
  speechBubble,
  flag,
  sun,
  location,
  notificationOn,
  notificationOff,
  id,
  permit,
  passport,
  graph,
  transport,
  send,
  gift,
  lightbulb,
  education,
  vaccination,
  support,
  trophy,
  science,
  diamond,
  square,
  drawingHand,
  laptop,
  foodServing,
  idea,
  bookManual,
  financeReceipt,
 } from './MultiUse/index'
 
 import {
  add,
  addRound,
  addCompany,
  addUser,
  arrowDown,
  arrowLeft,
  arrowUp,
  arrowRight,
  bubbleCheck,
  bubbleCheckFilled,
  bubbleClear,
  bubbleClearFilled,
  bubbleExclamation,
  bubbleExclamationFilled,
  bubbleMin,
  bubblePause,
  bubblePlay,
  bubblePlus,
  bubbleQuestion,
  bubbleQuestionFilled,
  cardView,
  caretUp,
  caretDown,
  checkmark,
  chevronDown,
  chevronLeft,
  chevronUp,
  chevronRight,
  close,
  closePanel,
  crop,
  deleteIcon,
  dividerVertical,
  download,
  dropdownCaretsC,
  dropdownCaretsR,
  duplicate,
  edit,
  expand,
  eyeOff,
  eyeOn,
  filter,
  home,
  inbox,
  listView,
  loading,
  locked,
  menu,
  menuUnread,
  minus,
  more,
  newTab,
  options,
  plus,
  print,
  repeat,
  repeatChecked,
  rotate,
  share,
  sort,
  thumbsDown,
  thumbsUp,
  unlocked,
  upload,
  warning,
  warningFilled
} from './Navigation/index'

import {
  company,
  shiftTime,
  shiftCancellation,
  cancellationFF,
  substitution,
  negotiation,
  impersonation,
  block,
  checkout,
  flexpool,
  hourlyRateEuro,
  euroCrossed,
  hourlyRateEuroEdit,
  hourlyRateUK,
  poundCrossed,
  hourlyRateUKEdit,
  priceMatch,
  directPayment,
  paymentReceived,
  paymentCancelled,
  sharingTips,
  vat,
  codeOfConduct,
  insurance,
  attendency,
  punctuality,
  userPermissions,
  flexibleTimes,
  breakRequired,
  proBadge,
  starFullColor,
  starHalfColor,
  starEmptyColor,
  logoTemperMobile,
  logoTemper,
  bookmarkFlag,
  temperApp,
} from './TemperSpecific/index'

import {
  android,
  apple,
  facebookColor,
  facebookMono,
  instagramColor,
  instagramMono,
  googleColor,
  googleMono,
  linkedinColor,
  linkedinMono,
  twitterColor,
  twitterMono
} from './Socials/index'

import {
  achievementsMedal,
  achievementsAward,
  achievementsDoItTogether,
  achievementsCup,
  achievementsPerfect,
  clothingCap,
  clothingShirt,
  clothingTie,
  contactChat,
  contactHeadset,
  contactMail,
  contactOpenMail,
  contactPhone,
  contactSpeechBubble,
  contactText,
  documentBlankIllustrative,
  documentFilledIllustrative,
  documentPassport,
  foodDrinksBeer,
  foodDrinksChampagne,
  foodDrinksCoffee,
  foodDrinksIcecream,
  foodDrinksLemon,
  foodDrinksLemonSlice,
  foodDrinksNoodles,
  foodDrinksPizza,
  foodDrinksPokebowl,
  foodDrinksWine,
  hospitalityAssistentChef,
  hospitalityBarista,
  hospitalityBartending,
  hospitalityCatering,
  hospitalityChef,
  hospitalityCleaning,
  hospitalityHosting,
  hospitalityHousekeeping,
  hospitalityRoomService,
  hospitalityServing,
  hospitalitySitecrew,
  hospitalityTraining,
  hospitalityWardrobe,
  jobHoreca,
  jobHospitality,
  jobLogistics,
  jobRetail,
  logisticsDelivery,
  logisticsMover,
  logisticsOrderpicker,
  logisticsWarehouseAssistent,
  moneyBilling,
  moneyCalculator,
  moneyCash,
  moneyCashRegister,
  moneyCashStack,
  moneyPayout,
  moneyPiggyBank,
  moneySmartPricing,
  moneyTipping,
  moneyTippingNone,
  natureCloud,
  natureEarth,
  natureMoon,
  natureSun,
  natureTree,
  officeBooks,
  officeCouch,
  officePlants,
  officeSuitcase,
  officeTemperature,
  othersCactus,
  othersCar,
  othersConstruction,
  othersDisco,
  othersFinish,
  othersFireplace,
  othersGrowth,
  othersHeart,
  othersIdea,
  othersKite,
  othersLightning,
  othersMovie,
  othersNavigation,
  othersObservatory,
  othersOkSign,
  othersPaperplane,
  othersSettings,
  othersSmileyHappy,
  othersSmileySad,
  othersStar,
  partyBalloons,
  partyCelebrations,
  partyHat,
  partyPopper,
  platformAgreement,
  platformCancellationPolicy,
  platformClient,
  platformCompany,
  platformDispute,
  platformFlexpool,
  platformFreeflexer,
  platformFreeflexers,
  platformInsurance,
  platformLanguage,
  platformLocation,
  platformLocked,
  platformNegotiation,
  platformNotify,
  platformProfile,
  platformRemove,
  platformSearch,
  platformSettings,
  platformSubstitution,
  platformSwitchLanguage,
  platformTeam,
  promotionProductPromotion,
  promotionStreetClerk,
  retailCheckout,
  retailCustomerService,
  retailSales,
  screenDashboard1,
  screenDashboard2,
  screenDesktop,
  screenLaptop,
  screenMobile,
  statisticsGraph1,
  statisticsGraph2,
  statisticsPieChart,
  temperBeABoss,
  temperFoosball,
  temperGetTogether,
  temperMakeMagic,
  temperTableTennis,
  temperUnicorn,
  temperValueWeBattleForTheBest,
  temperValueWeDoItForThem,
  temperValueWeMoveToImprove,
  temperValueWeOwnIt,
  temperValueWeShareToSucceed,
  timeCalendar,
  timeClock,
  timeDate,
  timeFlexible,
  timeHourglass,
  timeRecurring,
  timeStopwatch,
} from './Illustrative/index'

import { IconNames } from '@temperworks/types'

interface Props {
  name: IconNames
  position?: string
  color?: string
  size?: string | 'large'
}
const props = defineProps<Props>()
const icons : { [key: string]: any } = {
  minus,
  caretUp,
  caretDown,
  search,
  person,
  calendar,
  clock,
  glasses,
  glassesCrossed,
  timer,
  shoppingBag,
  alertOff,
  alertOn,
  document,
  documentAccepted,
  documentDeclined,
  telephone,
  email,
  profile,
  folder,
  profileSolid,
  camera,
  heart,
  image,
  imageEmpty,
  speechBubble,
  flag,
  sun,
  location,
  notificationOff,
  notificationOn,
  id,
  permit,
  passport,
  graph,
  transport,
  send,
  gift,
  lightbulb,
  education,
  vaccination,
  support,
  trophy,
  science,
  diamond,
  square,
  drawingHand,
  laptop,
  foodServing,
  add,
  addRound,
  addCompany,
  addUser,
  arrowDown,
  arrowLeft,
  arrowUp,
  arrowRight,
  bubbleCheck,
  bubbleCheckFilled,
  bubbleClear,
  bubbleClearFilled,
  bubbleExclamation,
  bubbleExclamationFilled,
  bubbleMin,
  bubblePause,
  bubblePlay,
  bubblePlus,
  bubbleQuestion,
  bubbleQuestionFilled,
  cardView,
  checkmark,
  chevronDown,
  chevronLeft,
  chevronUp,
  chevronRight,
  close,
  closePanel,
  crop,
  deleteIcon,
  dividerVertical,
  download,
  dropdownCaretsC,
  dropdownCaretsR,
  duplicate,
  edit,
  expand,
  eyeOff,
  eyeOn,
  filter,
  home,
  inbox,
  listView,
  loading,
  locked,
  menu,
  menuUnread,
  more,
  newTab,
  options,
  plus,
  print,
  repeat,
  repeatChecked,
  rotate,
  share,
  sort,
  thumbsDown,
  thumbsUp,
  unlocked,
  upload,
  warning,
  warningFilled,
  android,
  apple,
  facebookColor,
  facebookMono,
  instagramColor,
  instagramMono,
  googleColor,
  googleMono,
  linkedinColor,
  linkedinMono,
  twitterColor,
  twitterMono,
  company,
  shiftTime,
  shiftCancellation,
  cancellationFF,
  substitution,
  negotiation,
  impersonation,
  block,
  checkout,
  flexpool,
  hourlyRateEuro,
  euroCrossed,
  hourlyRateEuroEdit,
  hourlyRateUK,
  poundCrossed,
  hourlyRateUKEdit,
  priceMatch,
  directPayment,
  paymentReceived,
  paymentCancelled,
  sharingTips,
  vat,
  codeOfConduct,
  insurance,
  attendency,
  punctuality,
  userPermissions,
  flexibleTimes,
  breakRequired,
  proBadge,
  starFullColor,
  starHalfColor,
  starEmptyColor,
  logoTemperMobile,
  logoTemper,
  achievementsMedal,
  achievementsAward,
  achievementsDoItTogether,
  achievementsCup,
  achievementsPerfect,
  clothingCap,
  clothingShirt,
  clothingTie,
  contactChat,
  contactHeadset,
  contactMail,
  contactOpenMail,
  contactPhone,
  contactSpeechBubble,
  contactText,
  documentBlankIllustrative,
  documentFilledIllustrative,
  documentPassport,
  foodDrinksBeer,
  foodDrinksChampagne,
  foodDrinksCoffee,
  foodDrinksIcecream,
  foodDrinksLemon,
  foodDrinksLemonSlice,
  foodDrinksNoodles,
  foodDrinksPizza,
  foodDrinksPokebowl,
  foodDrinksWine,
  hospitalityAssistentChef,
  hospitalityBarista,
  hospitalityBartending,
  hospitalityCatering,
  hospitalityChef,
  hospitalityCleaning,
  hospitalityHosting,
  hospitalityHousekeeping,
  hospitalityRoomService,
  hospitalityServing,
  hospitalitySitecrew,
  hospitalityTraining,
  hospitalityWardrobe,
  jobHoreca,
  jobHospitality,
  jobLogistics,
  jobRetail,
  logisticsDelivery,
  logisticsMover,
  logisticsOrderpicker,
  logisticsWarehouseAssistent,
  moneyBilling,
  moneyCalculator,
  moneyCash,
  moneyCashRegister,
  moneyCashStack,
  moneyPayout,
  moneyPiggyBank,
  moneySmartPricing,
  moneyTipping,
  moneyTippingNone,
  natureCloud,
  natureEarth,
  natureMoon,
  natureSun,
  natureTree,
  officeBooks,
  officeCouch,
  officePlants,
  officeSuitcase,
  officeTemperature,
  othersCactus,
  othersCar,
  othersConstruction,
  othersDisco,
  othersFinish,
  othersFireplace,
  othersGrowth,
  othersHeart,
  othersIdea,
  othersKite,
  othersLightning,
  othersMovie,
  othersNavigation,
  othersObservatory,
  othersOkSign,
  othersPaperplane,
  othersSettings,
  othersSmileyHappy,
  othersSmileySad,
  othersStar,
  partyBalloons,
  partyCelebrations,
  partyHat,
  partyPopper,
  platformAgreement,
  platformCancellationPolicy,
  platformClient,
  platformCompany,
  platformDispute,
  platformFlexpool,
  platformFreeflexer,
  platformFreeflexers,
  platformInsurance,
  platformLanguage,
  platformLocation,
  platformLocked,
  platformNegotiation,
  platformNotify,
  platformProfile,
  platformRemove,
  platformSearch,
  platformSettings,
  platformSubstitution,
  platformSwitchLanguage,
  platformTeam,
  promotionProductPromotion,
  promotionStreetClerk,
  retailCheckout,
  retailCustomerService,
  retailSales,
  screenDashboard1,
  screenDashboard2,
  screenDesktop,
  screenLaptop,
  screenMobile,
  statisticsGraph1,
  statisticsGraph2,
  statisticsPieChart,
  temperBeABoss,
  temperFoosball,
  temperGetTogether,
  temperMakeMagic,
  temperTableTennis,
  temperUnicorn,
  temperValueWeBattleForTheBest,
  temperValueWeDoItForThem,
  temperValueWeMoveToImprove,
  temperValueWeOwnIt,
  temperValueWeShareToSucceed,
  timeCalendar,
  timeClock,
  timeDate,
  timeFlexible,
  timeHourglass,
  timeRecurring,
  timeStopwatch,
  idea,
  bookManual,
  financeReceipt,
  bookmarkFlag,
  temperApp,
}
</script>

<template>
  <component
    :is="icons[props.name] || 'logo'"
    :fill="props.color || 'dark'"
    :position="props.position || 'right'"
    :size="props.size || 'large'"
  />
</template>
