<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  HeaderContainer,
  StickyNavbar,
} from '@temperworks/components'
import { useCountryStore } from '../../store/country'
import { useUserStore } from '../../store/user'
import { useSettingsStore } from '../../store/settings'
import { useAuthStore } from '../../store/auth'
import { useFreeflexerProfileStore } from '../../store/freeflexer/profile'
import unauthenticatedMenu from '../../composables/freeflexer/navigationUnAuth'
import freeflexerDropdown from '../../composables/freeflexer/freeflexerDropdown'
import freeflexerTopNavigation from '../../composables/freeflexer/navigationAuth'
import freeflexerStickyNavbar from '../../composables/freeflexer/freeflexerStickyNavbar'
import { useMigrationStatus } from '../../composables/useMigrationStatus'

const text = useI18n()
const countryStore = useCountryStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const authStore = useAuthStore()
const nuxtApp = useNuxtApp()
const freeflexer = useFreeflexerProfileStore()
const { freeflexerFinOverviewNavigation } = useMigrationStatus()

authStore.getToken()
userStore.getUser()
freeflexer.getOnboardingProgress()

function openCountrySelector () {
  countryStore.setCountryChange()
}

function logout () {
  nuxtApp.$router.push('/logout')
}

/**
 * main menu items
 */
const menuItems = computed(() => {
  if (!userStore.isUserLoggedIn) {
    return unauthenticatedMenu(text).unauthenticatedMenu
  }

  return freeflexerTopNavigation(text, countryStore.backend).freeflexerTopNavigation
})

/**
 * dropdown menu items
 */
const menuItemsDropdown = computed(() => {
  if (!userStore.isUserLoggedIn) {
    return []
  }

  return freeflexerDropdown(text, countryStore.backend, freeflexerFinOverviewNavigation.value).freeflexerDropdown
})

function stopImpersonation () {
  userStore.setImpersonation(false, null)
}
</script>
<template>
  <HeaderContainer
    :authenticated-user="userStore.isUserLoggedIn"
    :menu-items="menuItems"
    :menu-items-dropdown="menuItemsDropdown"
    :mobile-device="settingsStore.isTablet"
    :country="countryStore.country"
    :user="userStore.user"
    :show-complete-profile="true"
    :complete-profile-percentage="freeflexer.completeProfilePercentage"
    :is-impersonating="userStore.isImpersonating"
    @stop-impersonation="stopImpersonation"
    @country="openCountrySelector()"
    @logout="logout()"
  />
  <StickyNavbar
    v-if="userStore.loggedInUser && settingsStore.isMobile"
    :items="freeflexerStickyNavbar(
      text,
      countryStore.backend,
      countryStore.countrySpecificRoutes).freeflexerStickyNavbar
    "
  />
</template>
