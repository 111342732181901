<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9947 0.00537109H0.00585938V19.9943H19.9947V0.00537109Z"
      fill="#00FF86"
    />
    <path
      d="M4.40616 15.7592C4.26891 15.8415 4.11244 15.8862 3.95246 15.8889C3.62839 15.8889 3.55301 15.6381 3.55301 15.1879V13.7359H4.40616V12.9026H3.55301V11.7729L2.6582 12.3333V12.9037H2.01172V13.737H2.65839V15.3111C2.65839 16.557 3.36209 16.7302 3.84561 16.7302C4.3219 16.7302 4.60487 16.5546 4.60487 16.5546L4.40616 15.7592Z"
      fill="#0F0017"
    />
    <path
      d="M10.4567 13.0797C9.94483 12.7432 9.28501 12.7834 8.78779 12.9534C8.59365 13.0208 8.40836 13.1114 8.23593 13.2232C7.70723 12.7376 6.94519 12.7628 6.38686 12.9534C5.6976 13.1895 5.29297 13.6824 5.29297 13.6824V16.6506H6.19038V13.9099C6.19038 13.9099 6.48667 13.6517 7.05445 13.6517C7.6026 13.6517 7.69371 13.9513 7.69371 14.4573V16.6506H8.59093V13.9099C8.59093 13.9099 8.88723 13.6517 9.4552 13.6517C10.0035 13.6517 10.0946 13.9513 10.0946 14.4573V16.6506H10.9919V14.1197C10.9919 13.7069 10.8017 13.3071 10.4567 13.0797Z"
      fill="#0F0017"
    />
    <path
      d="M11.6545 13.6739C12.1108 13.2222 12.5923 12.8252 13.43 12.8252C14.3891 12.8252 15.2502 13.5659 15.2502 14.762C15.2502 15.7445 14.5821 16.727 13.3232 16.727V15.9096C13.8821 15.9096 14.3452 15.5022 14.3452 14.81C14.3452 14.1433 13.9269 13.6561 13.3232 13.6561C13.0461 13.6588 12.7767 13.7478 12.5524 13.9108V17.9885H11.6543L11.6545 13.6739Z"
      fill="#0F0017"
    />
    <path
      d="M17.9889 13.6559H17.4871C17.2099 13.6587 16.9405 13.7478 16.7163 13.9108V16.6482H15.8184V13.6739C16.2735 13.2222 16.7565 12.8252 17.593 12.8252C17.7258 12.8252 17.8582 12.8396 17.988 12.868L17.9889 13.6559Z"
      fill="#0F0017"
    />
  </svg>
</template>
